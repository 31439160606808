import BaseService from "./base-service";

class CustomerService extends BaseService {
    async getCustomerIdByUser(userId?: number | null) {
        return await this.get<number>('/customer/getCustomerIdByUser', { params: { userId } });
    }

    async isCustomerTaxExempt(customerId: number) {
        return await this.get<boolean>('/customer/IsCustomerTaxExempt', { params: { customerId } });
    }

    async hasTaxCertificate(customerId: number) {
        return await this.get<boolean>('/customer/hasTaxCertificate', { params: { customerId } });
    }

    async getPersonalDiscount(customerId: number) {
        return await this.get<number>('/customer/getPersonalDiscount', { params: { customerId } });
    }
}

export default new CustomerService();